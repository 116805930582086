import { mdiAccountGroup, mdiFileStar, mdiCog, mdiStore, mdiShieldCrown, mdiGamepadVariant, mdiApi } from '@mdi/js'

export const menus = Object.freeze({
  global: {
    items: {
      clients: {
        icon: mdiAccountGroup,
        route: 'ClientsList',
        root: true,
      },
      markets: {
        icon: mdiStore,
        route: 'BrandsList',
        root: true,
      },
      reviews: {
        icon: mdiFileStar,
        route: 'ReviewsList',
        root: true,
      },
      superRgpd: {
        icon: mdiGamepadVariant,
        route: 'SuperRgpdList',
        root: true,
      },
      userSA: {
        icon: mdiShieldCrown,
        route: 'UserSAList',
        root: true,
      },
      endpoints: {
        icon: mdiApi,
        route: 'EndpointList',
        root: true,
      },
    },
  },
  gmb: {
    items: {
      profile: {
        icon: mdiCog,
        route: 'GmbAdmin',
        root: true,
      },
    },
  },
  configuration: {
    items: {
      settings: {
        icon: mdiCog,
        route: 'settingsCreation',
        root: true,
      },
    },
  },
})
