<template>
  <div class="backoffice">
    <v-app-bar app height="56" class="tw-shadow lg:tw-px-2">
      <HeaderMenu :loading="loading" @toggleDrawer="toggleDrawer" />

      <v-spacer></v-spacer>
      <HeaderModules :loading="loading" :modules="modules" :current-module="currentModule" @update="updateModule" />
      <HeaderAccount :loading="loading" :current-user="currentUser" />
    </v-app-bar>
    <Navigation :drawer="drawer" :loading="loading" @toggleDrawer="toggleDrawer" />
    <v-main>
      <v-overlay :value="loading" v-if="loading" absolute>
        <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
      </v-overlay>
      <router-view class="backoffice__view" v-else />
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getUserToken, isNavigationAccess, isExternalModule } from '@/utils/auth.util'
import { sleep } from '@/utils/utilities.util'
import { URLS } from '@/config/urls.config'
import Navigation from '@/components/Navigation.vue'
import HeaderMenu from '@/components/Header/Menu.vue'
import HeaderModules from '@/components/Header/Modules.vue'
import HeaderAccount from '@/components/Header/Account.vue'

export default {
  name: 'Backoffice',
  components: {
    Navigation,
    HeaderMenu,
    HeaderAccount,
    HeaderModules,
  },
  data: () => ({}),
  async mounted() {
    this.setLoading(true)
    await this.checkAuthentication()
    this.setLoading(false)
  },
  computed: {
    ...mapState({
      modules: state => state.backoffice.modules,
      currentModule: state => state.backoffice.currentModule,

      drawer: state => state.backoffice.drawer,
      loading: state => state.backoffice.loading,
      currentUser: state => state.backoffice.currentUser,
    }),
  },
  methods: {
    ...mapActions({
      setDrawer: 'backoffice/setDrawer',
      setLoading: 'backoffice/setLoading',
      setUpdating: 'backoffice/setUpdating',
      getAuthentication: 'backoffice/getAuthentication',
      userLogout: 'backoffice/userLogout',
    }),
    async updateModule({ module, openNewTab }) {
      this.setUpdating(true)
      await isExternalModule(module, openNewTab)
      this.setUpdating(false)
    },
    toggleDrawer(bool) {
      this.setDrawer(typeof bool === 'boolean' ? bool : !this.drawer)
    },
    async checkAuthentication() {
      if (getUserToken() !== undefined) {
        try {
          await this.getAuthentication()
          if (!isNavigationAccess(this.$route.meta.roles, this.currentUser)) {
            this.$router.push({ name: 'Error' })
          }
        } catch {
          await this.userLogin()
        }
      } else {
        await this.userLogin()
      }
    },
    async userLogin() {
      await this.userLogout()
      window.location.assign(
        `${URLS.SSO}/?app_version=sa&redirect=${encodeURIComponent(location.pathname + location.search)}&sign_out=1`
      )
      await sleep(2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.backoffice {
  @include screen-flex;

  &__view {
    @include screen-flex($full-height: true);
  }
}
</style>
