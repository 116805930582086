<template>
  <v-skeleton-loader v-if="loading" type="image" width="32" height="32" class="tw-rounded-full tw--mr-1 sm:tw-mr-1" />
  <div class="tw--mr-3 sm:tw--mr-1" v-else>
    <v-menu offset-y left :close-on-content-click="false" max-width="300" transition="slide-y-reverse-transition">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon v-bind="attrs" v-on="{ ...menu }">
          <v-avatar size="32" color="primary" class="tw-text-white"> {{ userInitials }} </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="primary" class="tw-text-white tw-justify-center">
            {{ userInitials }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="tw-font-medium">
              {{ currentUser.firstname }} {{ currentUser.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>{{ icons.mdiTranslate }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content class="account__lang">
            <v-list-item-title>Lang</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="account__actions">
            <v-autocomplete
              v-model="$i18n.locale"
              :items="locales"
              id="i18nLocale"
              flat
              dense
              item-value="code"
              item-text="text"
              hide-details
            />
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="toggleDarkTheme">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiWeatherNight }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('header.account.menu.toggleDarkTheme') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch :input-value="$vuetify.theme.dark"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon class="tw-text-red-600">{{ icons.mdiPowerStandby }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="tw-text-red-600">
              {{ $t('header.account.menu.logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { URLS } from '@/config/urls.config'
import { mdiAccountCircleOutline, mdiWeatherNight, mdiPowerStandby, mdiTranslate } from '@mdi/js'
import { locales } from '@/config/locales.config'

export default {
  name: 'HeaderAccount',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    currentUser: {
      required: true,
    },
  },
  data: () => {
    return {
      locales,
      icons: {
        mdiAccountCircleOutline,
        mdiWeatherNight,
        mdiPowerStandby,
        mdiTranslate,
      },
    }
  },
  computed: {
    userInitials() {
      return `${this.currentUser.firstname.charAt(0)}${this.currentUser.lastname.charAt(0)}`
    },
  },
  methods: {
    ...mapActions({
      userLogout: 'backoffice/userLogout',
    }),
    toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('dark_theme', +this.$vuetify.theme.dark)
      document.documentElement.classList.toggle('tw-dark')
    },
    async logout() {
      await this.userLogout()
      window.location.assign(`${URLS.SSO}/?sign_out=1`)
    },
  },
}
</script>

<style lang="scss" scoped>
.account {
  &__lang {
    flex: 1 1 100%;
  }

  &__actions {
    flex: 1 1 auto;
  }
}
</style>
