export const locales = Object.freeze([
  {
    code: 'en',
    text: 'English',
  },
  {
    code: 'fr',
    text: 'Français',
  },
])
