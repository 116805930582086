import {
  mdiShieldCrownOutline,
  mdiDatabaseEyeOutline,
  mdiMapMarkerOutline,
  mdiBullhornOutline,
  mdiQrcode,
  mdiCardsOutline,
  mdiBullseyeArrow,
  mdiCogOutline,
} from '@mdi/js'

export const modulesConfig = Object.freeze({
  network: {
    icon: mdiMapMarkerOutline,
  },
  profilehub: {
    icon: mdiDatabaseEyeOutline,
  },
  superadmin: {
    icon: mdiShieldCrownOutline,
  },
  communication: {
    icon: mdiBullhornOutline,
  },
  touchpoints: {
    icon: mdiQrcode,
  },
  playandcollect: {
    icon: mdiCardsOutline,
  },
  platform: {
    icon: mdiBullseyeArrow,
  },
  settings: {
    icon: mdiCogOutline,
  },
})
