<template>
  <v-navigation-drawer app :value="drawer" @input="toggleDrawer">
    <div class="tw-flex tw-items-center tw-justify-center tw-px-4 tw-h-14">
      <v-skeleton-loader v-if="loading" type="image" width="100%" height="32" />
      <v-img
        alt="GTR Suite Logo"
        contain
        :src="
          this.$vuetify.theme.dark
            ? require('@/assets/images/logo-dark.svg')
            : require('@/assets/images/logo-light.svg')
        "
        height="32"
        position="center left"
        v-else
      />
    </div>

    <div v-if="loading" class="tw-px-4">
      <template v-for="(n, i) in 3">
        <v-skeleton-loader type="image" :key="`heading-${i}`" class="tw-mt-12 tw-rounded-2xl" height="14" width="50%" />
        <template v-for="(n, j) in 3">
          <v-skeleton-loader type="image" :key="`text-${i}-${j}`" height="16" class="tw-mt-7 tw-rounded-2xl" />
        </template>
      </template>
    </div>

    <v-list nav dense class="tw-mb-2" v-else>
      <template v-for="(group, groupKey, i) in menus">
        <v-subheader class="tw-mt-8 primary--text tw-font-bold" :key="`subheader-${i}`">
          {{ groupLabel(groupKey).toUpperCase() }}
        </v-subheader>
        <template v-for="(items, menuKey, i) in group.items">
          <v-list-group v-if="items.childs" :group="items.path" :key="`navigation-${menuKey}-${i}`">
            <template v-slot:activator>
              <v-list-item-icon class="tw-mr-4">
                <v-icon>{{ menuIcon(groupKey, menuKey) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuLabel(groupKey, menuKey) }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(subMenus, childKey) in items.childs"
              :key="childKey"
              :to="subMenuRoute(groupKey, menuKey, childKey)"
              :exact="isSubMenuRoot(groupKey, menuKey, childKey)"
            >
              <v-list-item-icon class="tw-ml-4 tw-mr-4">
                <v-icon>{{ subMenuIcon(groupKey, menuKey, childKey) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subMenuLabel(groupKey, menuKey, childKey) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item-group v-else :key="`navigation-single-${groupKey}-${i}`" color="primary">
            <v-list-item :key="menuKey" :to="menuRoute(groupKey, menuKey)" :exact="isMenuRoot(groupKey, menuKey)">
              <v-list-item-icon class="tw-mr-4">
                <v-icon>{{ menuIcon(groupKey, menuKey) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menuLabel(groupKey, menuKey) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { menus } from '@/config/menus.config'
export default {
  props: {
    drawer: {
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    menus,
  }),
  methods: {
    toggleDrawer(bool) {
      this.$emit('toggleDrawer', bool)
    },
    groupLabel(groupKey) {
      return this.$t(`navigation.${groupKey}.label`)
    },
    menuLabel(groupKey, menuKey) {
      return this.$t(`navigation.${groupKey}.item.${menuKey}.title`)
    },
    subMenuLabel(groupKey, menuKey, childKey) {
      return this.$t(`navigation.${groupKey}.item.${menuKey}.item.${childKey}`)
    },
    menuIcon(groupKey, menuKey) {
      return menus[groupKey]?.items[menuKey]?.icon
    },
    subMenuIcon(groupKey, menuKey, childKey) {
      return menus[groupKey]?.items[menuKey]?.childs[childKey]?.icon
    },
    menuRoute(groupKey, menuKey) {
      return { name: menus[groupKey]?.items[menuKey]?.route, params: this.$route.params }
    },
    subMenuRoute(groupKey, menuKey, childKey) {
      return {
        name: menus[groupKey]?.items[menuKey]?.childs[childKey]?.route,
        params: this.$route.params,
      }
    },
    isMenuRoot(groupKey, menuKey) {
      return menus[groupKey]?.items[menuKey]?.root
    },
    isSubMenuRoot(groupKey, menuKey, childKey) {
      return menus[groupKey]?.items[menuKey]?.childs[childKey]?.root
    },
  },
}
</script>
